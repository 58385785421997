import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import { navigate } from 'gatsby-link'
import Layout from '@components/Layout'
import Content from '@components/Content'
import ContactIcon from '@assets/contact-inline.svg';
import ReactMarkdown from "react-markdown";

import './contact.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isValidated: false}
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <StaticQuery query={graphql`
  query ContactQuery {
    strapiPage(slug: {eq: "kontakt"}) {
    id
    content
    title
  }
  }
  `}
     render={data => (
      <Layout>
        <Content>
          <h1 className="heading">{data.strapiPage.title}</h1>
          <div className="contact">
            <div className="contact__image">
              <ContactIcon className="contact__icon"/>
              <div className="contact__text">
                <ReactMarkdown source={data.strapiPage.content}/>
              </div>
            </div>
            <form
              name="contact"
              method="post"
              action="/success/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              className={"contact-form"}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact"/>
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange}/>
                </label>
              </div>
              <div className="contact-form__wrapper">
                <input
                  className="contact-form__input"
                  type={'text'}
                  name={'name'}
                  onChange={this.handleChange}
                  id={'name'}
                  required={true}
                />
                <label className="contact-form__label" htmlFor={'name'}>Imię i nazwisko</label>
              </div>
              <div className="contact-form__wrapper">
                <input
                  className="contact-form__input"
                  type={'subject'}
                  name={'subject'}
                  onChange={this.handleChange}
                  id={'subject'}
                  required={true}
                />
                <label className="contact-form__label" htmlFor={'subject'}>Temat</label>
              </div>
              <div className="contact-form__wrapper">
                <input
                  className="contact-form__input"
                  type={'email'}
                  name={'email'}
                  onChange={this.handleChange}
                  id={'email'}
                  required={true}
                />
                <label className="contact-form__label" htmlFor={'mail'}>Email</label>
              </div>
              <div className="contact-form__wrapper">
                <input className="contact-form__input" id="phone" name="phone" type="tel" required/>
                <label className="contact-form__label" htmlFor={'phone'}>Telefon</label>
              </div>
              <div className="contact-form__wrapper">
                  <textarea
                    className="contact-form__input"
                    rows={'6'}
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    required={true}/>
                <label className="contact-form__label" htmlFor={'message'}>Wiadomość</label>
              </div>
              <button type="submit" className="button button--primary contact-form__submit">Wyślij wiadomość</button>
            </form>
          </div>
        </Content>
      </Layout>
     )}
    />
    )
  }
}
